/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useCallback, useContext, useMemo, useState } from 'react'

import AlignmentContainer from '../../../components/alignment-container/AlignmentContainer'
import Button from '../../../components/button/Button'
import DropdownInput from '../../../components/dropdown-input/DropdownInput'
import { DropdownData } from '../../../components/dropdown-input/types'
import { InfoTable } from '../../../components/info-table/InfoTable'
import { TableView } from '../../../components/info-table/types'
import PageContainer from '../../../components/page-container/PageContainer'
import Spacer from '../../../components/spacer/Spacer'
import { customFetch } from '../../../_data/api'
import { useAppSelector } from '../../../_globals/hooks'
import { NotificationContext } from '../../../_globals/notifications/notification-context'
import { RootState } from '../../../_globals/state-store'
import { log } from '../../../_utilities/logging'
import { gatherTableData } from './helpers'
import { Environment } from '../../../_environments/environment'

const userAppSelector = (state: RootState) => state.user

/**
 * Portfolio Home Page
 * @returns {JSX.Element}
 */
const PortfolioHome = (): JSX.Element => {
  const { showNotification } = useContext(NotificationContext)
  const userSelector = useAppSelector(userAppSelector)
  const [data, setData] = useState<any>()
  const [pageError, setPageError] = useState<string>('')
  const [viewDataAs, setViewDataAs] = useState<TableView>('table')
  const [plantDropdownData, setPlantDropdownData] = useState<DropdownData[]>([])
  const [isApiBusy, setIsApiBusy] = useState<boolean>(false)

  /**
   * Contractual Table Data from API
   */
  const contractualTableData = useMemo(() => {
    const rawData = data?.port_summary ?? []

    return gatherTableData(rawData)
  }, [data?.port_summary])

  /**
   * Summary Table Data from API
   */
  const summaryTableData = useMemo(() => {
    const rawData = data?.summary ?? []

    return gatherTableData(rawData)
  }, [data?.summary])

  /**
   * Technical Table Data from API
   */
  const technicalTableData = useMemo(() => {
    const rawData = data?.tech ?? []

    return gatherTableData(rawData)
  }, [data?.tech])

  /**
   * Fetch page data from API
   */
  const fetchData = useCallback(
    (plantList: number[]) => {
      if (isApiBusy) {
        showNotification({
          title: 'Loading, One Moment',
          dismissAfter: 1500,
          type: 'info',
        })

        return null
      }

      setIsApiBusy(true)
      const startDate = new Date()
      startDate.setHours(0, 0, 0, 1)
      const endDate = new Date()
      endDate.setHours(23, 55, 59, 0)

      customFetch(
        'POST',
        'summary/portfolio',
        {
          plant_id_list: plantList,
          start_date: Math.round(startDate.getTime() / 1000).toString(),
          end_date: Math.round(endDate.getTime() / 1000).toString(),
        },
        'application/json',
        false,
        false,
      )
        .then(result => {
          if (result.code === 200) {
            setData(result.data)

            // Check if all arrays in the result data are empty
            if (
              result.data.summary.length === 0 &&
              result.data.tech.length === 0 &&
              result.data.port_summary.length === 0 &&
              result.data['LIVE PLANTS'] === 0 &&
              result.data['TOTAL CAPACITY(MWp)'] === 0 &&
              result.data['TOTAL GENERATION (MWH)'] === 0 &&
              result.data['CARBON SAVED (LBS)'] === 0
            ) {
              showNotification({
                title: 'No Data Found',
                dismissAfter: 1500,
                type: 'info',
              })
            }
          } else {
            log(
              '08ZVLMGWTBN8XURMQC42',
              'PortfolioHome.tsx',
              'fetchData',
              'error',
              'Error loading portfolio data',
              { result, plantList },
            )
            setPageError('Error Loading Portfolio Data')

            showNotification({
              title: 'Error Loading Portfolio Data',
              dismissAfter: 1500,
              type: 'error',
            })
          }
        })
        .catch((error: Error) => {
          log(
            'KCJLGB6BPP0R9PJC3LV2',
            'PortfolioHome.tsx',
            'fetchData',
            'error',
            'summary/tables Error',
            { error, plantList },
          )

          setPageError('Error Loading Portfolio Data')
          showNotification({
            title: 'Error Loading Portfolio Data',
            dismissAfter: 1500,
            type: 'error',
          })
        })
        .finally(() => {
          setIsApiBusy(false)
        })
    },
    [isApiBusy, showNotification],
  )

  /**
   * Handle page ready event
   */
  const handlePageReady = useCallback(() => {
    console.info("userSelector.plants",userSelector.plants)

     // Create a map of plant IDs to names
     const plantNameMap = new Map<number, string>(
      Environment.whitePlantListNames.map(({ id, name }) => [id, name])
    )

    setPlantDropdownData(() =>
      userSelector.plants.map(plant => ({
        // displayValue: plant.name,
        displayValue: plantNameMap.get(plant.id) || 'Unknown',
        // displayValue: plant.id.toString(),
        id: plant.id.toString(),
      }))
      .sort((a, b) => {
          if (a.displayValue < b.displayValue) {
            return -1
          }
          if (a.displayValue > b.displayValue) {
            return 1
          }
          return 0
        })
      ,
    )

    fetchData(userSelector.plants.map(plant => plant.id))
  }, [fetchData, userSelector.plants])

  /**
   * Handle view data change event
   * @param {TableView} view
   */
  const handleViewChange = useCallback((view: TableView) => {
    setViewDataAs(view)
  }, [])

  /**
   * Handle plant change event
   * @param {string} plant
   */
  const handlePlantChange = useCallback(
    (plant: string) => {
      const sanitizedPlantList =
        plant === ''
          ? userSelector?.plants.map(plantData => plantData.id) ?? []
          : [Number.parseInt(plant, 10)]

      fetchData(sanitizedPlantList)
    },
    [fetchData, userSelector?.plants],
  )

  return (
    <PageContainer
      height="100%"
      offsetTop="20px"
      offsetBottom="20px"
      offsetMode="padding"
      allowNotifications={true}
      allowUnauthenticated={false}
      pageError={pageError}
      navbarPosition="bottom"
      menuType="main"
      pageTitle="Portfolio Home"
      showNavBar={true}
      onPageReady={() => handlePageReady()}>
      <AlignmentContainer align="center" display="block">
        <Button
          text={viewDataAs === 'table' ? 'View as Cards' : 'View as Tables'}
          theme="main"
          callback={() =>
            handleViewChange(viewDataAs === 'table' ? 'card' : 'table')
          }
        />
        <Spacer direction="vertical" amount="20px" display="block" />
        <DropdownInput
          unselectedDisplayValue="All Plants"
          data={plantDropdownData}
          onChange={newValue => handlePlantChange(newValue)}
          display="block"
          width="200px"
        />
      </AlignmentContainer>
      <InfoTable
        title="Contractual Data"
        data={contractualTableData}
        view={viewDataAs}
      />
      <InfoTable
        title="Summary Data"
        data={summaryTableData}
        view={viewDataAs}
      />
      <InfoTable
        title="Technical Data"
        data={technicalTableData}
        view={viewDataAs}
      />
      {/* {console.info("dropdown data: ", plantDropdownData)} */}
    </PageContainer>
  )
}

export default PortfolioHome
