import { ApiResponse } from '../_types/api'
import { getEnvironmentSettings } from '../_utilities/config'

const environment = getEnvironmentSettings()

/**
 * A custom wrapped implementation of fetch
 *
 * @param {('GET' | 'POST')} method The method to use
 * @param {string} path The path to use
 * @param {object} [body] The body to use
 * @param {('application/json' | 'multipart/form-data')} [contentType] The content type to use
 * @param {boolean} [useAuthenticationServerUrl] Whether to use the authentication server url
 * @param {boolean} [useCors] Whether to use cors
 * @returns {Promise<ApiResponse>} The api response
 */
const customFetch = (
  method: 'GET' | 'POST',
  path: string,
  body?: object | FormData,
  contentType?: 'application/json' | 'multipart/form-data',
  useAuthenticationServerUrl?: boolean,
  useCors?: boolean,
): Promise<ApiResponse> => {
  const sanitizedPath = path[0] === '/' ? path.slice(1) : path
  let url = useAuthenticationServerUrl
    ? environment.authenticationServerUrl
    : environment.serverUrl

  if (useCors) {
    url = `https://cors-proxy.fringe.zone/${url}`
  }

  url += `/${sanitizedPath}`

  return new Promise(resolve => {
    // Define headers as Record<string, string> to support dynamic property access
    const headers: Record<string, string> = {
      Accept: 'application/json',
      'Content-Type': contentType || 'application/json',
    }

    // Handle body and contentType appropriately
    const fetchOptions: RequestInit = {
      method,
      headers,
    }

    if (contentType === 'application/json') {
      fetchOptions.body = body ? JSON.stringify(body) : null
    } else if (contentType === 'multipart/form-data') {
      fetchOptions.body = body as FormData
      // Remove 'Content-Type' header for 'multipart/form-data' as the browser sets it automatically
      delete headers['Content-Type']
    } else {
      fetchOptions.body = null
    }
    fetch(url, fetchOptions)
      .then(response => {
        if (response.status === 200) {
          const responseType = response.headers.get('Content-Type')

          if (responseType && responseType.includes('application/json')) {
            return response.json().then(data => {
              if (data?.status?.http_code && data.status.http_code !== 200) {
                console.error(data)
                return resolve({
                  code: 500,
                  message: 'Error',
                  data,
                })
              }

              return resolve({
                code: 200,
                message: 'Success',
                data,
              })
            })
          } else {
            return response.text().then(text => {
              if (text === 'Success' || text === 'success') {
                return resolve({
                  code: 200,
                  message: 'Success',
                  data: text,
                })
              } else {
                console.error(text)
                return resolve({
                  code: 500,
                  message: 'Error',
                  data: text,
                })
              }
            })
          }
        }

        console.error(response)
        return resolve({
          code: 500,
          message: 'Error',
          data: response,
        })
      })
      .catch(error => {
        console.error(error)
        resolve({
          code: 500,
          message: 'Error',
          data: error,
        })
      })
  })
}

export { customFetch }
